import { useEffect, useState } from "react";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import React from "react";
import { FMFilters,StateReportModel, ModelForFilters, StateReportModel_Resp } from "../models/StateReportModel";
import { Box, Stack, width } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { MenuDropdown } from "../components/Elements/MenuDropdown";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Chip, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, SelectProps, debounce } from "@mui/material";
import { writeXlsxFile } from "../helper/utils";
import { Field, FieldArray, FieldAttributes, Form, Formik, useField, useFormik, useFormikContext } from 'formik';
import CancelIcon from "@mui/icons-material/Cancel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StateReportList } from "../components/Lists/StateReportList";
import Swal from "sweetalert2";
import moment from "moment";
import { Console } from "console";
import StateReportMarkCompleted from "./StateReportMarkCompleted";
import { apiClient } from "../helper/api";
import {GridFilterItem, GridFilterModel, GridLinkOperator, GridSortModel } from "@mui/x-data-grid-pro";
import formatPrice from "../helper/currencyFormatter";

export const StateReport = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const initValue: StateReportModel_Resp = {
        largeReport: undefined,
        completedPage: undefined,
        stateReportModel_Respdata: [],
        totalCount: 0,
        pageCount: 0,
        pageSize: 50,
        currentPage: 0
    }

    const [ReportModule, setReportModule] = useState<StateReportModel_Resp>(initValue)
    const [value, setValue] = React.useState(8);
    const PloicyNum = React.useRef("");
    const insName = React.useRef("");
    const effectiveDateEd = React.useRef("");
    const effectiveDateSt = React.useRef("");
    const stateData = React.useRef(0);
    const customerData = React.useRef(0);
    const [selectedButton, setSelectedButton] = useState<number>(1);
    const [selectedRows, setSelectedRows] = useState<StateReportModel[]>()
    const [openCompletedDialog, setOpenCompletedDialog] = useState<string>('');
    const [completedPage, setCompletedPage] = useState<boolean>(false);
    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    // const [_currentPage, setCurrentPage] = React.useState(1);
    // const [_pageSize, setPageSize] = React.useState(50);
    //const [itemList, setItemList] = useState<StateReportModel[]>(initValue.stateReportModel_Respdata)
    const [currentPage, setCurrentPage] = useState(initValue.currentPage)
    const [pageSize, setPageSize] = useState(initValue.pageSize)
    const [queryOptions, setQueryOptions] = React.useState<FMFilters>();
    const sortModelRef = React.useRef<GridSortModel>([]);
    

    const keysNamesFromXlsx = [
     'StateName', 'Licensee','LicenseNumber', 'Name', 'DueDate',
       'LicenseNumber',  'Period','Payment', 'NotaryStamp', 
       'Report', 'PaymentType', 'WetSignature', 'ZeroIndividual','Status','Client','MainLicensee','ZeroEntity'
    ]

    const keyNames: (keyof StateReportModel)[] = [
        'stateName', 'name', 'period' , 'dueDate','payment','report', 'paymentType', 'wetSignature','notaryStamp', 
         'licensee','licenseNumber', 'client','dateFinalReportSentToClient','mainLicensee','zeroIndividual','zeroEntity','status', 'largeReport','notesToClient','taxReported'
    ]
    
    const handleOpenCompletedDialog = (status: string) => {
        setOpenCompletedDialog(status);
    };

    const handleCloseCompletedDialog = () => {
        setOpenCompletedDialog('');
    };

    const handlePageChange = (val: number) => {
        setCurrentPage(val)
      }
      const handlePageSizeChange = (val: number) => {
        if (val <= 0) return
        setPageSize(val)
      }

    const handleSubmitCompletedDialog = (completedDate: string) => {
        setOpenCompletedDialog('');
        setloading(true);
        const selectedRowsArray: StateReportModel[] = [];
        //const newFilters: FMFilters = [];
        selectedRows?.map(m => {
            console.log("mmmm",m)
            m.dateFinalReportSentToClient = completedDate
            m.partnerId = m.partnersId
            selectedRowsArray.push(m)
        });
        apiClient(`/StateReports/SaveStateReport`, {
            method: "POST",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }),
            body: JSON.stringify(selectedRowsArray)
        })
        .then((res) => {
            if (!res) throw new Error('Something went wrong')
            setSelectedRows([]);
            Swal.fire({
                title: "Successful!",
                text: "Success",
                icon: "success",
                confirmButtonText: "OK",
            })
           
            fetchNewData('getStateReport', 50, 1,'', '', completedPage);
            setloading(false);
            })
        .catch((error) => {
            console.log(error);
            setloading(false);
        });
        

    };

    
    const handleSubmitStatus = (status: string) => {
       // setOpenCompletedDialog('');
        setloading(true);
        const selectedRowsArray: StateReportModel[] = [];
        selectedRows?.map(m => {
            console.log("mmmm",m)
            m.status = status
            m.partnerId = m.partnersId
            selectedRowsArray.push(m)
        });
        apiClient(`/StateReports/StatusStateReport`, {
            method: "POST",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }),
            body: JSON.stringify(selectedRowsArray)
        })
        .then((res) => {
            if (!res) throw new Error('Something went wrong')
            setSelectedRows([]);
            Swal.fire({
                title: "Successful!",
                text: "Success",
                icon: "success",
                confirmButtonText: "OK",
            })
           
            fetchNewData('getStateReport', 50, 1,"","",completedPage);
            setSelectedOption([])
            setloading(false);
            })
        .catch((error) => {
            console.log(error);
            setloading(false);
        });
        

    };
    const initFilterModel: ModelForFilters = {};

    const debouncedOnFilterChange = debounce(
      (
        action: string,
        filterModel: GridFilterModel,
        pageSize: number,
        pageNumber: number,
        field: string,
        sort?: string,
        isCompletedPage?: boolean, 
      ) => {
        setloading(true);
    
        const newFilters: FMFilters = {
          items: filterModel.items.map((item) => {
            let value = item.value ? item.value.trim() : "";
    
            return {
              columnField: item.columnField,
              value: value,
            };
          }),
        };
    
        // Update query options with the new filters
        setQueryOptions(newFilters);
    
        const hasValue = newFilters.items.some((item) => item.value !== "");
    
        const fetchData = (filters: FMFilters) => {
          const endpoint = action;
          if (endpoint) {
            fetchNewData(endpoint, pageSize, pageNumber, field, sort, isCompletedPage, filters);
          }
        };
    
        if (hasValue) {
          // Fetch data with filters if any filter value is present
          fetchData(newFilters);
        } else {
          // Clear filters if no values are present
          const clearedFilters: FMFilters = { items: [] };
          setQueryOptions(clearedFilters);
          // Fetch data without filters
          setPageSize(50); // Reset page size to default if needed
          fetchData(clearedFilters);
        }
      },
      1200
    );
    
    const fetchNewData = (
      action: string,
      pageSize: number,
      pageNumber: number,
      field: string,
      sort?: string,
      isCompletedPage?: boolean,
      newFilters?: FMFilters
    ) => {
      setloading(true);
      setSelectedRows([]);
    
      const options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
    
      if (effectiveDateSt.current) {
        const start = new Date(effectiveDateSt.current);
        effectiveDateSt.current = start.toLocaleDateString('en-US', options);
      }
    
      if (effectiveDateEd.current) {
        const end = new Date(effectiveDateEd.current);
        effectiveDateEd.current = end.toLocaleDateString('en-US', options);
      }
    
    //   const selectedValuesArray = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
    //   const paymentFilingScheduleId = selectedValuesArray.join(',');
    const selectedCustomerElement = document.getElementById('selectedCustomers') as HTMLInputElement | null;
    const selectedCustomerIds = selectedCustomerElement ? selectedCustomerElement.value : '';

    const selectedTabElement = document.getElementById('selectedTab') as HTMLInputElement | null;
    const selectedTab = selectedTabElement ? selectedTabElement.value : '1';
      const filterModel = {
        ...initFilterModel, 
        
        pageSize: pageSize,
        pageNumber: pageNumber,
       field:  sortModelRef.current[0]?.field ?? '',
        sort:  sortModelRef.current[0]?.sort ?? '',
        completedPage: selectedTab == '2',
        state: newFilters?.items.find((m) => m.columnField === 'stateName')?.value || '',
        name: newFilters?.items.find((m) => m.columnField === 'name')?.value || '',
        period: newFilters?.items.find((m) => m.columnField === 'period')?.value || '',
        dueDate: newFilters?.items.find((m) => m.columnField === 'dueDate')?.value || '',
        paymentType: newFilters?.items.find((m) => m.columnField === 'paymentType')?.value || '',
        licensee: newFilters?.items.find((m) => m.columnField === 'licensee')?.value || '',
        payment: newFilters?.items.find((m) => m.columnField === 'payment')?.value || '',
        report: newFilters?.items.find((m) => m.columnField === 'report')?.value || '',
        wetSignature: newFilters?.items.find((m) => m.columnField === 'wetSignature')?.value || '',
        notaryStamp: newFilters?.items.find((m) => m.columnField === 'notaryStamp')?.value || '',
        status: newFilters?.items.find((m) => m.columnField === 'status')?.value || '',
        client: newFilters?.items.find((m) => m.columnField === 'client')?.value || '',
        mainLicensee: newFilters?.items.find((m) => m.columnField === 'mainLicensee')?.value || '',
        zeroEntity: newFilters?.items.find((m) => m.columnField === 'zeroEntity')?.value || '',
        largeReport: newFilters?.items.find((m) => m.columnField === 'largeReport')?.value || '',
        notesToClient: newFilters?.items.find((m) => m.columnField === 'notesToClient')?.value || '',
        taxReported: newFilters?.items.find((m) => m.columnField === 'taxReported')?.value || '',
        licenseNumber: newFilters?.items.find((m) => m.columnField === 'licenseNumber')?.value || '',

        
      };
      const params = new URLSearchParams({
        pageSize: String(filterModel.pageSize),
        pageNumber: String(filterModel.pageNumber),
        field: filterModel.field,
        sort: filterModel.sort,
        completedPage: String(completedPage),
        state: filterModel.state,
        name: filterModel.name,
        period: filterModel.period,
        dueDate: filterModel.dueDate,
        paymentType: filterModel.paymentType,
        licensee: filterModel.licensee,
        payment: filterModel.payment,
        report: filterModel.report,
        wetSignature: filterModel.wetSignature,
        notaryStamp: filterModel.notaryStamp,
        status: filterModel.status,
        client: filterModel.client,
        mainLicensee: filterModel.mainLicensee,
        zeroEntity: filterModel.zeroEntity,
        largeReport: filterModel.largeReport,
        notesToClient: filterModel.notesToClient,
        taxReported: filterModel.taxReported,
        licenseNumber: filterModel.licenseNumber,
        partnersId: selectedCustomerIds
      });

      fetch(`${config.url.API_URL}/TaxesAndFeesFilingSchedule/${action}?${params}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
          }
          return res.json();
        })
        .then((responseJson) => {
          setloading(false);
          const fetchedData = responseJson.data || [];
          if (fetchedData.length > 0) {
            setReportModule({
                stateReportModel_Respdata: fetchedData,
                largeReport: "",
                completedPage: "",
                totalCount: fetchedData.length,
                pageSize: 50,                          
                pageCount: Math.ceil(fetchedData.length / 50), 
                currentPage: 1                         
            });
        } else {
            setReportModule(initValue);
        }
        
        })
        .catch((error) => {
          setloading(false);
          console.error(error);
        });
    };
    
    const handleChange = async (
        event: React.SyntheticEvent | undefined,
        newValue: number
    ) => {
        setCompletedPage(newValue == 2)
        setloading(true);
        setSelectedButton(newValue);
        const action = "getStateReport";
        const element = document.getElementById('selectedTab');
        
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const onExportXlsx = (values: StateReportModel[]) => {
        setloading(true);
        if (values.length === 0) {
            return
        }
        let filteredKeyNames = keyNames;
        if (selectedButton === 1) {
                filteredKeyNames = keyNames.filter(key => key !== 'dateFinalReportSentToClient');
            }
        const items = values.map(item => {
            const update: (string | number | Date | undefined)[] = [];
    
            filteredKeyNames.map((key: keyof StateReportModel) => {
                if (key === 'dueDate' || key === 'dateFinalReportSentToClient') {
                    update.push(moment.utc(item[key]).format("MM/DD/YYYY"));
                } else if (key === 'taxReported') {
                    const value = item[key] ?? '';
                    update.push(formatPrice(value));
                } else {
                    update.push(item[key] as string | number | undefined);
                }
            });
            
    
            return update;
        });
        const updates = [
            filteredKeyNames,
            ...items
        ]
        writeXlsxFile(updates)
        setloading(false);
    }
    // const handleExportXlsxNew = async (val: StateReportModel[]) => {
    //     try {
    //         setloading(true);

    //         const action = "getStateReport";

    //         const response = await fetchExportData(action, selectedOptions, ReportModule?.totalCount, 1);
    //         let values = response
    //         console.log("values",values);
    //         console.log("response",response);
    //         if (val && val.length > 0) {
    //             values = val
    //         }
    //         if (values.length <= 0) {
    //             alert('Please upload a xlsx file')
    //             return
    //         }
    //         onExportXlsx(values);
    //         Swal.fire({
    //             title: "Export Successful!",
    //             text: "Success",
    //             icon: "success",
    //             confirmButtonText: "OK",
    //         });
    //         setSelectedRows([]);
    //         setloading(false);
    //     } catch (error) {
    //         setloading(false);
    //         console.error("Error occurred:", error);
    //     }
    // }
    const handleDataChange = async (e: string) => {
        var result = selectedOptions.toLocaleString().split(',').filter((item) => item !== e);
        setSelectedOption(result);
        onChangeCustomer(result);
    }
    const handleExportXlsxNew= async (val: StateReportModel[]) => {
        try {
            setloading(true);
            
            // Check if there are selected rows; if so, pass them for export
            if (selectedRows && selectedRows.length > 0) {
                onExportXlsx(selectedRows);
                Swal.fire({
                    title: "Export Successful!",
                    text: "Only selected data has been exported.",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                setloading(false);
                return;
            }
    
            // Otherwise, proceed to fetch data for export as before
            const action = "getStateReport";
            const response = await fetchExportData(action, selectedOptions, ReportModule?.totalCount, 1, completedPage);
            
            if (response && response.length > 0) {
                onExportXlsx(response);
                Swal.fire({
                    title: "Export Successful!",
                    text: "All data has been exported.",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            } else {
                alert('No data available for export');
            }
    
            setloading(false);
        } catch (error) {
            setloading(false);
            console.error("Error occurred during export:", error);
        }
    };
    
    async function onChangeCustomer(customers: string | string[]) {
        const action = "getStateReport";
        await fetchExportData(action, customers, 50, 1, completedPage);
    }
    async function fetchExportData(action: string, customers: string | string[], pageSize: number, pageNumber: number,  isCompletedPage?: boolean,) {
        setloading(true);
        
        const selectedValuesArray = Array.isArray(customers) ? customers : [customers];
        const partnersId = selectedValuesArray.join(',');
    
        try {
            // Fetch data from API
            const res = await apiClient(`/TaxesAndFeesFilingSchedule/${action}?partnersId=${customers}&&pageSize=${pageSize}&&pageNumber=${pageNumber}&&completedPage=${isCompletedPage}`,
                {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }),
                }
            );
            if (!res) throw new Error('Something went wrong')
    
            // Stop loading once the response is received
            setloading(false);
    
            // Handle response
                const fetchedData = res.data;

                if (fetchedData && fetchedData.length > 0) {
                    const reportModuleData: StateReportModel_Resp = {
                        stateReportModel_Respdata: fetchedData,
                        largeReport: "", 
                        completedPage: "",
                        totalCount: fetchedData.length,         
                        pageSize: 50,                          
                        pageCount: Math.ceil(fetchedData.length / 50), 
                        currentPage: 1                         
                    };
                    
                    // Update with the fetched data
                    setReportModule(reportModuleData);
                    
                    // Return the data for further use
                    return reportModuleData.stateReportModel_Respdata;
                } else {
                    const reportModuleData: StateReportModel_Resp = {
                        stateReportModel_Respdata: [],
                        largeReport: "", 
                        completedPage: "",
                        totalCount: 0,         
                        pageSize: 50,                          
                        pageCount: 0, 
                        currentPage: 1                         
                    };
                    setReportModule(reportModuleData);
                    return [];
                }
        } catch (error) {
            // Handle errors in the fetch process
            setloading(false);
            console.error("Error occurred during fetch:", error);
            return []; // Return an empty array in case of error
        }
    }
    const initialUserdata = { partnersId: 0, customerName: "" };
    const [Customer, setCustomer] = useState([initialUserdata]);
    const fetchCustomer = () => {
        apiClient("/Partners/getPartners", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((responseJson) => {
                if (!responseJson) return
                setCustomer(responseJson.data);
            })
    };

    const [selectedOptions, setSelectedOption] = React.useState<string[] | string>([]);
    const status = [
         'Not Started', 'Waiting on Client', 'No Report Required'  
        // 'Missing Documents', 'Pending Documents', 'Flagged','Waiting on Client', 'Ready to File', 'Pending Autofile', 'Pending state review', 'State Flagged', 'Filed', 'IsClosed', 
    ]

    const FMSelectCustomer: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {

        return (
            <FormControl fullWidth sx={{}}  >
                <input type="hidden" id="selectedTab" value={1} />
                <InputLabel id="State-Select-Label">Customers</InputLabel>
                <Select
                    // {...field}
                    disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                    label={"Customers"}

                    className="border-round main-form-2"
                    multiple
                    labelId="State-Select-Label"
                    value={selectedOptions}
                    IconComponent={ExpandMoreIcon}
                    onChange={(e) => {
                        const selectedValues = e.target.value;
                        setSelectedOption(selectedValues);
                        onChangeCustomer(selectedValues);
                    }}
                    renderValue={(selected) => (
                        <Stack gap={1} direction="row" flexWrap="wrap">
                            <input type="hidden" id="selectedCustomers" value={selected.toLocaleString()} />
                            {selected.toLocaleString().split(',').map((value) => (
                                <Chip

                                    key={value}
                                    label={Customer.find(x => x.partnersId.toString() === value)?.customerName}
                                    onDelete={() =>
                                        // setSelectedOption(
                                        //     selectedOptions.toLocaleString().split(',').filter((item) => item !== value)
                                        // )
                                        handleDataChange(value)
                                    }
                                    disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                                    deleteIcon={
                                        <CancelIcon
                                            onMouseDown={(event) => event.stopPropagation()}
                                        />
                                    }
                                />
                            ))}

                        </Stack>
                    )}
                >
                    {Customer.map((Cust, key) =>
                        <MenuItem key={key} value={Cust.partnersId}>  {Cust.customerName} </MenuItem>
                    )}
                </Select>
            </FormControl>
        )
    }

        useEffect(() => {
            if (isInitialRender) {
                fetchNewData('getStateReport', 50, 1, '', '', completedPage);
                fetchCustomer();
                setIsInitialRender(false); // Mark as no longer the initial render
            }
        }, [isInitialRender])

        useEffect(() => {
            if (!isInitialRender) {
                fetchNewData('getStateReport', 50, 1, '', '', completedPage);
            }
        }, [completedPage, isInitialRender]);

    const handleMarkCompleted = () => {
        handleOpenCompletedDialog('true');
    }
    return (
        <><LocalizationProvider dateAdapter={AdapterDayjs}>

            <Box>
                <div style={{ position: "fixed", top: "0", left: "0", zIndex: "9990", width: "100%" }}>
                    {loading && <LinearProgress color="info" sx={{ backgroundColor: "#132D3D" }} />}
                </div>
   
            </Box>
          <Box sx={{ width: "100%" }}>
          {/* <DialogTitle className='card-title' >State Reports</DialogTitle> */}
                  <Box>
                  <div className="col-lg-12    ">
                    <div
                      className=" d-flex  "
                      style={{ marginBottom: "28px" }} role="group"
                      aria-label="Basic example"
                    >
                    <Grid
                        container
                        spacing={3} // Adjust the spacing between grid items
                        className="filter-grid"
                        display="flex"
                        justifyContent="flex-end" // Align the buttons to the right
                        alignItems="right"
                    >
                    <React.Fragment>
                    <div className="col-6  text-center ">
                          <button type="button"
                            className={`btn-tab btn ${selectedButton === 1 ? "btn-Selected" : "btn-primary1"
                              }`}
                            {...a11yProps(8)}
                            onClick={() => handleChange(undefined, 1)}
                          >
                           Outstanding Queue
                          </button>
                        </div>
                        <div className="col-6 text-center">
                            <button
                                type="button"
                                
                                className={`btn-tab btn ${selectedButton === 2 ? "btn-Selected" : "btn-primary"
                                }`}
                                {...a11yProps(5)}
                                onClick={() => handleChange(undefined, 2)}
                            >
                                Completed Queue
                            </button>
                        </div>
                        <Grid item xl={2} sm={12} xs={12} minWidth={150}>
                            <FormControl  sx={{ width: '100%' }}>
                            <FMSelectCustomer name="paymentFilingScheduleId" />
                            </FormControl>
                        </Grid>
                        { selectedButton === 1 && selectedRows?.length !== 0 && selectedRows?.length !== undefined ?
                            <Grid item minWidth={150} className="select-input-border d-flex">
                                
                                <button
                                    onClick={() => handleMarkCompleted()}
                                    className="active-btn-blue"
                                    style={{ width: '100%' }}
                                >
                                    Sent To Client
                                </button>
                            </Grid> : ''
                        }
                         {selectedRows?.length !== 0 && selectedRows?.length !== undefined ?

                           // <div className="dropdowm-class ms-2" style={{ zIndex: "9" }} >
                           <Grid item minWidth={150} className="select-input-border d-flex">
                                <MenuDropdown
                                    menuTitle={'Status'}
                                    items={status}
                                    onItemClick={handleSubmitStatus} />
                            </Grid>
                            //</div>
                            : ''}
                        <Grid item xl={2} sm={12} xs={12} minWidth={150} className="select-input-border d-flex">
                            <button
                                onClick={() => handleExportXlsxNew([])}
                                className="active-btn-blue"
                                style={{ width: '100%' }} // Ensure all buttons have equal width
                            >
                                Export
                            </button>
                        </Grid>
                        </React.Fragment>
                    </Grid>
                    </div>
                  </div>
                </Box>       
          </Box>

        </LocalizationProvider>
        {openCompletedDialog && <StateReportMarkCompleted
                open={!!openCompletedDialog}
                handleClose={handleCloseCompletedDialog}
                handleSubmit={handleSubmitCompletedDialog}
                selectedRows={selectedRows}
            />}
       
            <StateReportList ReportModuleCL={ReportModule} fetchDataMain={fetchNewData} selectedButton={selectedButton} setSelectedRows={setSelectedRows} setCurrentPage={setCurrentPage}  setPageSize={setPageSize} _currentPage={currentPage}  _pageSize={pageSize}  completedPage={completedPage} selectedRows={selectedRows} debouncedOnFilterChange={debouncedOnFilterChange} /></>
    );
}
